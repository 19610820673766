* {
  --highlight-color: #db2e3a;
  --menu-bg: #271c1d;
  --menu-text: #f6efef;
  --menu-separator: #402e2f;
  --bg: #945d62;
  --content-bg: #fcfcfc;
  --content-text: #121212;
  --red: #91151d;
  --soft-separator: #d9cfcf;
  --progress-track: #9a9696;
  --progress-thumb: #4e3d43;
}
