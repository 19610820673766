@import "../../styles/media";

.MainMenu {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 0 100%;
  grid-template-rows: 3rem calc(100% - 3rem);
  transition: 0.3s ease grid-template-columns;
  font-size: 1.4rem;

  @include desktop {
    font-size: 1.1rem;
    grid-template-rows: 3rem calc(100% - 3rem);
  }

  &.open {
    grid-template-columns: 100% 0;

    @include desktop {
      grid-template-columns: 20ch calc(100% - 20ch);
    }
  }

  .actions {
    grid-column: 1 / span 2;
    background-color: var(--menu-bg);
    display: flex;
    z-index: 100;
    justify-content: space-evenly;

    @include desktop {
      justify-content: left;
    }

    button,
    a {
      width: 3rem;
      height: 3rem;
      display: flex;
      color: var(--menu-text);
      font-size: 1.8rem;
      background: none;
      border: none;
      padding: 0.8rem 1rem;
      cursor: pointer;
      z-index: 100;
      position: relative;
      transition: margin 0.3s ease;
      text-align: left;
      align-items: center;

      @include desktop {
        font-size: 1.6rem;
      }
    }
  }

  .menu {
    width: calc(max(100%, 20ch));
    transition: margin 0.3s ease;
    background-color: var(--menu-bg);

    .links {
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        color: var(--menu-text);
        padding: 0.5rem 1rem;

        @include desktop {
          padding: 0.3rem 1rem;
        }
      }
    }
  }

  & > .content {
    background: var(--bg);
    overflow-y: auto;
  }
}
