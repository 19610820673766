@import "../../styles/media";

.MySheets {
  width: 100%;
  max-width: 20cm;
  margin: auto;
  background-color: var(--content-bg);
  min-height: 100%;

  input {
    border: none;
    width: 100%;
    font-size: 1.1rem;
    padding: 0.5rem 0.7rem;
  }

  .voices-wrapper {
    .voice {
      display: flex;
      color: var(--content-text);
      text-decoration: none;
      justify-content: space-between;
      padding: 0.5rem 0.7rem;
      border-top: 2px solid var(--soft-separator);

      &:hover {
        color: var(--highlight-color);

        h2,
        h3 {
          color: var(--highlight-color);
        }
      }

      h2 {
        font-size: 1.5rem;
        margin: 0;
        overflow-wrap: anywhere;
        color: var(--content-text);
      }

      h3 {
        font-size: 1.2rem;
        font-weight: normal;
        margin: 0;
        overflow-wrap: anywhere;
        color: #363535;
      }

      p {
        font-size: 1rem;
        margin: 0;
        color: #363535;
      }

      .icon-wrapper {
        display: none;

        @include desktop {
          display: flex;
        }

        font-size: 1.8rem;
        padding: 0.3rem;
      }
    }
  }
}
