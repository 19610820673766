@use "../../styles/media.scss";

.Midi {
  display: flex;

  .content {
    display: flex;
  }

  @include media.desktop {
    #show-tempo-controls {
      border-left: 3px solid var(--menu-separator);
      padding-left: 1.06rem;
    }
  }
}
