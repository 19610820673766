@import "../../styles/media";
@import "../../styles/colors";

.Songs {
  width: 100%;
  max-width: 20cm;
  margin: auto;
  background-color: var(--content-bg);
  min-height: 100%;

  input {
    border: none;
    width: 100%;
    font-size: 1.1rem;
    padding: 0.5rem 0.7rem;
  }

  .songs-wrapper {
    summary {
      display: block;
    }

    .song {
      color: var(--content-text);
      text-decoration: none;
      padding: 0.5rem 0.7rem;
      border-top: 2px solid var(--soft-separator);

      .header {
        display: flex;
        justify-content: space-between;

        &:hover {
          color: var(--highlight-color);

          h2,
          h3 {
            color: var(--highlight-color);
          }
        }
      }

      .voice {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        color: var(--content-text);
        font-size: 1.2rem;
        transition: color 0.1s ease, background-color 0.1s ease;

        &:hover {
          color: var(--highlight-color);

          p {
            color: var(--highlight-color);

            &.conductor {
              color: transparent;
            }
          }
        }

        .players {
          margin-left: 1rem;
          margin-bottom: 1rem;
        }
      }

      h2 {
        font-size: 1.5rem;
        margin: 0;
        overflow-wrap: anywhere;
        color: var(--content-text);
      }

      h3 {
        font-size: 1.2rem;
        font-weight: normal;
        margin: 0;
        overflow-wrap: anywhere;
        color: #363535;
      }

      p {
        font-size: 1.2rem;
        margin: 0;
        color: #363535;

        &.conductor {
          color: transparent;
        }
      }

      .icon-wrapper {
        display: none;

        @include desktop {
          display: flex;
        }

        font-size: 1.8rem;
        padding: 0.3rem;
      }
    }
  }
}
