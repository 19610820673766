@import "../../styles/media";

.SheetViewer {
  width: 100%;

  // Wundert mich, dass es nicht calc(100% - 0.6rem) wegen der #progress-bar ist.
  // Vielleicht wird irgendwie ne unsichtbare horizontale Scrollbar nicht mitgezählt?
  height: 100%;
  overflow: hidden;

  & > .content {
    overflow: hidden;
    height: calc(100% - 2rem);
    touch-action: none;

    @include desktop {
      height: calc(100% - 1rem);
    }

    .document {
      display: flex;
      flex-wrap: wrap;

      @include desktop {
        flex-wrap: nowrap;
      }
    }
  }

  #progress-bar {
    width: 100%;
    height: 2rem;

    @include desktop {
      height: 1rem;
    }

    .rc-slider {
      padding: 0;
      height: 100%;

      .rc-slider-handle {
        width: 8px;
        height: 100%;
        border: none;
        border-radius: 0;
        background: var(--progress-thumb);
        cursor: pointer;
        margin: 0;
        padding: 0;
        opacity: 1;

        &.rc-slider-handle-dragging {
          box-shadow: none;
        }
      }

      .rc-slider-rail {
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: var(--progress-track);
        margin: 0;
        padding: 0;
        border-radius: 0;
      }

      .rc-slider-track {
        height: 100%;
        border-radius: 0;
        background-color: var(--highlight-color);
      }
    }
  }

  #instrument-controls {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      user-select: none;
      z-index: 2;
      background-color: var(--content-bg);
      position: relative;
      padding: 1rem;
      border-radius: 0 0 0.5rem 0.5rem;
      max-height: calc(100vh - 4rem);
      overflow-y: auto;
    }

    .background-filter {
      z-index: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(0 0 0 / 50%);
    }
  }
}
