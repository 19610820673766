.InstrumentControls {
  display: flex;
  color: var(--content-text);
  margin-bottom: 0.3rem;
  justify-content: space-between;
  align-items: center;

  .slider-and-name {
    .name {
      margin-bottom: -0.3rem;
    }

    input {
      width: 9.75rem;
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 1rem;
    margin: -1rem -0.5rem -1rem 0;
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--content-text);

    &.solo {
      color: var(--highlight-color);
    }
  }
}
