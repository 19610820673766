@use "styles/colors.scss";
@use "styles/fonts.scss";
@use "./../node_modules/rc-slider/assets/index.css";

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  caret-color: transparent;
  box-sizing: border-box;
  user-select: none;
}

input {
  caret-color: var(--content-text);
}
