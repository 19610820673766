@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
}

body {
  font-family: Roboto, sans-serif;
}
