@use "styles/media.scss";

.TempoControls {
  position: relative;

  input {
    width: 15rem;
  }

  .markers {
    width: 100%;
    height: 2rem;
    padding-top: 0.7rem;

    button {
      font-size: 1.1rem;
      position: absolute;
      border: none;
      background: none;
      border-radius: 0.3rem;
      color: var(--content-text);
      background-color: var(--soft-separator);
      padding: 0.15rem 0.5rem;
      margin: 0;

      &:first-child {
        left: 0;
      }

      &:nth-child(2) {
        left: calc(33.333% - 0.6ch - 0.5rem);
      }

      &:last-child {
        right: 0;
      }
    }
  }

  @include media.desktop {
    border-right: 3px solid var(--menu-separator);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 15rem;
    padding-right: 1rem;
    color: var(--menu-text);

    input {
      width: 9.75rem;
      background-color: transparent;
      padding-left: 1rem;

      &::-webkit-slider-thumb,
      &::-moz-range-thumb {
        width: 1.1rem;
        height: 1.1rem;
        background: var(--menu-text);
        border: none;
        border-radius: 100%;
        cursor: pointer;
      }

      &::-webkit-slider-runnable-track,
      &::-moz-range-track {
        width: 100%;
        height: 0.25rem;
        cursor: pointer;
        background: var(--menu-text);
        border-radius: 0.25rem;
      }
    }

    span {

    }
  }
}
